<template>
  <div>
    <hr v-if="!spinner"/>
    <b-spinner v-if="spinner" variant="primary" class="spinners"/>
    <div v-if="!spinner">
      <b-row class="mt-1">
        <b-col cols="12">
          <b-card class="oda-list-card">
            <b-row class="mt-50">
              <b-col cols="6">
                <h4>Oda Kayıtları</h4>
              </b-col>
              <b-col cols="6">
                <h4 class="text-right">Oda Sayısı : {{ this.$store.getters.getOdaListesi.length }}</h4>
              </b-col>
            </b-row>
            <hr class="myHr"/>
            <table class="table table-bordered mt-1" :key="count">
              <thead>
              <tr>
                <th scope="col">KOD</th>
                <th scope="col">ODA TÜRÜ</th>
                <th scope="col">KAT</th>
                <th scope="col">KAPASİTE</th>
                <th scope="col">PAYLAŞIM</th>
                <th scope="col">ONLİNE R.</th>
                <th scope="col">RENK</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in this.$store.getters.getOdaListesi"
                  :key="index"
              >
                <td>{{ item.odaKodu }}</td>
                <td>{{ item.odaTipi }}</td>
                <td>{{ item.kat }}</td>
                <td>Yatak: {{ item.yatak }} - Ek Yatak: {{ item.eYatak }}</td>
                <td>
                  <b-form-checkbox
                      :checked="item.paylasimli == 1"
                      name="check-button"
                      switch
                      inline
                      disabled
                  />
                </td>
                <td>
                  <b-form-checkbox
                      :checked="item.online == 1"
                      name="check-button"
                      switch
                      inline
                      disabled
                  />
                </td>
                <td>
                  <div
                      class="myColor"
                      :class="'bg-' + $store.getters.odaParams(item.odaRenk)"
                  >
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
  VBTooltip,
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import store from '@/store'

export default {
  components: {
    BSpinner,
    VBTooltip,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    quillEditor,
    BFormFile,
    vSelect,
  },
  data() {
    return {
      spinner: true,
      isEdit: false,
      howEditRoom: '',
      chooseID: '',
      count: 0,
      odaKodu: '',
      kat: '',
      yatak: '',
      ekYatak: '',
    }
  },
  methods: {},
  computed: {},
  created() {
    setTimeout(() => {
      this.spinner = false
    }, 200)
    store
        .dispatch('odaList')
        .then((res, position = 'bottom-right') => {
          const mesaj = store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          const mesaj = store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('odaListesiAction', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>

<style scoped>
@keyframes mymove {
  0% {
    background-color: pink;
  }

  25% {
    background-color: pink;
  }

  50% {
    background-color: #00ffd1;
  }

  75% {
    background-color: #00ffd1;
  }

  100% {
    background-color: #00ffd1;
    width: 480px;
  }
}

.editOnPrgoress {
  width: 0px;
  height: 4px;
  background: #38e54d;
  position: relative;
  animation: mymove 4000ms infinite;
  border-radius: 20px;
  transform: translateY(20px);
  /* animation-iteration-count: 1 !important; */
}

.odaKayitRow {
  justify-content: space-around !important;
}

.table td {
  text-align: center !important;
}

.myColor {
  width: 30px;
  height: 30px;
  border-radius: 14px;
  margin: 0 auto !important;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #31526f;
  color: white;
  padding: 0.6rem;
  border: none;
  text-align: center;
}

[dir] .table td {
  padding: 0.1rem !important;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #009879 !important;
  padding: 5px !important;
  color: white !important;
}

.card-body {
  padding: 10px 15px;
}

.myHr {
  margin-top: 0 !important;
}

.spinners {
  position: absolute !important;
  margin-left: 47% !important;
  margin-top: 15% !important;
  width: 3rem !important;
  height: 3rem !important;
  z-index: 900 !important;
}
</style>
<style>
.card-body {
  padding: 10px 15px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.editCard {
  border: inherit !important;
}

@keyframes editCard {
  from {
    background-color: red !important;
  }

  to {
    background-color: yellow !important;
  }
}

.oda-list-card .row {
  margin-right: 0;
  margin-left: 0;
}
</style>
